<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    :close-on-click-modal="false"
    width="50%"
    append-to-body
    @open="openDialog"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span>新增常用时间段</span>
    </div>
    <el-row>
      <el-time-picker
        v-model="startTime"
        value-format="HH:mm"
        format='HH:mm'
        class="date-box"
        :picker-options="{selectableRange:`00:00:00 -${endTime ? endTime+':00' : '23:59:00'}`}"
        clearable
        placeholder="开始时间">
      </el-time-picker>
      <span>—</span>
      <el-time-picker
        style="font-size: 16px;"
        v-model="endTime"
        clearable
        :picker-options="{selectableRange:`${startTime ? startTime+':00' : '00:00:00'}-23:59:00`}"
        format='HH:mm'
        value-format="HH:mm"
        placeholder="结束时间">
      </el-time-picker>
    </el-row>
    <el-row slot="footer">
      <el-button type="primary" @click="saveRoleForm" size="small">新 增</el-button>
      <el-button @click="close" type="info"  size="small">取 消</el-button>
    </el-row>
  </el-dialog>
</template>

<script>
  import { addUserPeriod} from '@/api/user'
  export default {
    props: {
      show: {
        default: false,
        type: Boolean
      },
    },
    data () {
      return {
        startTime:'',
        endTime:''
      }
    },
    methods: {
      async saveRoleForm(){
        if(this.startTime === '' || this.startTime === null || this.startTime === undefined) {
          window.$msg('开始时间不能为空',2);
          return  false
        }else if (this.endTime === '' || this.endTime === null || this.endTime === undefined) {
          window.$msg('结束时间不能为空',2);
          return  false
        }else if(this.startTime === this.endTime) {
          window.$msg('结束时间不能等于开始时间',2);
          return  false
        }
        const respone = await addUserPeriod({
          startTime: this.startTime,
          endTime:this.endTime
        })
        if(respone && respone.state === 'success') {
          window.$msg('新增成功')
          this.close();
        }
      },

      openDialog () {
        this.startTime = ''
        this.endTime = ''
      },

      close () {
        this.$emit('close')
      },

      async insertMenu(formData) {
        const response = await insertMenu({
          orderNum:this.formData.orderNum,
          state:this.formData.state?"1":"2"
        })
        if(response.state === 'success') {
          this.close()
          this.$emit('updataMenu')
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../style/dialog.scss';
</style>
